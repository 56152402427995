import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

class ArtistAlbums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      alben: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const match = this.props.match;
    const alben = `https://api.jamendo.com/v3.0/artists/albums/?client_id=2bae85e9&format=json&limit=50&imagesize=300&id=${match.params.id}`;

    fetch(alben)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            loaded: true,
            alben: data.results,
          });
          // console.log(data);
        },
        (error) => {
          this.setState({
            loaded: false,
            error,
          });
        }
      );
  }

  render() {
    const { error, loaded, alben } = this.state;

    if (error) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div>Irgendwas ist schief gelaufen... {error.message}</div>
          </div>
        </div>
      );
    } else if (!loaded) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <section className="home-section alben">
              <h4>Artist - {alben[0].name}</h4>
              <h3>Browse Albums</h3>

              <div className="outer">
                <SimpleBar style={{ maxHeight: 526 }}>
                  <div className="row m-0">
                    {alben[0].albums.map((album) => (
                      <div className="col-3" key={album.id}>
                        <Link
                          to={`/albums/${album.id}`}
                          title={album.name.replace("&amp;", "&")}
                        >
                          <div className="card-albums">
                            <div className="card-albums__image">
                              <img
                                src={album.image}
                                alt={album.name.replace("&amp;", "&")}
                              />
                            </div>
                            <div className="card-albums__text">
                              <h5>{album.name.replace("&amp;", "&")}</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </SimpleBar>
              </div>
            </section>
          </div>
        </div>
      );
    }
  }
}

export default ArtistAlbums;