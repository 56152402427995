import React from "react";
import Musicplayer from "./components/Musicplayer";

class App extends React.Component {
  render() {
    return (
      <>
        <Musicplayer />
      </>
    );
  }
}

export default App;
