import React from "react";
import SimpleBar from "simplebar-react";
import Playboard from "../partials/Playboard-Songs";

class Songs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      tracksAll: [],
      loaded: false,
      playing: false,
      songIndex: 0,
      currentTime: 0,
      duration: 0,
      loop: false,
      shuffle: false,
    };
  }

  componentDidMount() {
    const tracksAll =
      "https://api.jamendo.com/v3.0/tracks/?client_id=2bae85e9&format=json&limit=150&include=musicinfo+stats&order=popularity_week";

    fetch(tracksAll)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            loaded: true,
            tracksAll: data.results,
          });
          // console.log(data);
        },
        (error) => {
          this.setState({
            loaded: false,
            error,
          });
        }
      );
  }

  audioEl = React.createRef();
  timelineEl = React.createRef();
  durationEl = React.createRef();

  saveSongDuration = (e) => {
    this.setState({
      duration: e.target.duration,
    });
  };

  playSong = () => {
    const audio = this.audioEl.current;

    this.setState({
      playing: !this.state.playing,
    });

    if (this.state.playing) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  nextSong = () => {
    this.setState(
      (state) => {
        let currentIndex = state.songIndex + 1;
        if (currentIndex > this.state.tracksAll.length - 1) {
          return { songIndex: 0, playing: false };
        } else {
          return { songIndex: currentIndex, playing: false };
        }
      },
      () => this.playSong()
    );
  };

  prevSong = () => {
    this.setState(
      (state) => {
        let currentIndex = state.songIndex - 1;
        if (currentIndex < 0) {
          return {
            songIndex: this.state.tracksAll.length - 1,
            playing: false,
          };
        } else {
          return { songIndex: currentIndex, playing: false };
        }
      },
      () => this.playSong()
    );
  };

  handleTimeUpdate = (e) => {
    this.setState({
      currentTime: e.target.currentTime,
    });

    this.timeline(e);
  };

  selectTrack = (track) => {
    this.setState(
      {
        songIndex: track,
        playing: false,
      },
      () => this.playSong()
    );
  };

  audioEnd = () => {
    const audio = this.audioEl.current;
    if (!audio.ended) return;

    const { loop, shuffle, playing, tracksAll } = this.state;

    if (loop) {
      this.setState({ playing: !playing });
      audio.currentTime = 0;
      this.playSong();
    } else if (shuffle) {
      const totalTracks = tracksAll.tracks.length;
      const randomTrack = Math.floor(Math.random() * totalTracks);
      console.log(randomTrack);

      this.setState({
        songIndex: tracksAll.tracks[randomTrack],
        playing: !playing,
      });

      this.playSong();
    } else {
      this.nextSong();
    }
  };

  loop = () => {
    this.setState({ loop: !this.state.loop });
  };

  shuffle = () => {
    this.setState({ shuffle: !this.state.shuffle });
  };

  updateTimeline = (e) => {
    const audio = this.audioEl.current;
    const progressBar = this.timelineEl.current;
    const totalWidth = progressBar.offsetWidth;
    const offsetLeft = progressBar.getBoundingClientRect().left;
    const playedRatio = (e.pageX - offsetLeft) / totalWidth;
    const { duration } = this.state;

    audio.currentTime = playedRatio * duration;
  };

  timeline = () => {
    const playedBar = this.durationEl.current;
    const { currentTime, duration } = this.state;
    const playedRatio = (currentTime / duration) * 100;

    playedBar.style.transform = `translateX(${-(100 - playedRatio)}%)`;
  };

  timeFormat = (duration) => {
    let durationMinutes = Math.floor(duration / 60);
    let durationSeconds = Math.floor(duration % 60);

    if (durationSeconds < 10) {
      durationSeconds = `0${durationSeconds}`;
    }

    return `${durationMinutes}:${durationSeconds}`;
  };

  render() {
    const { error, loaded, tracksAll } = this.state;

    if (error) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div>Irgendwas ist schief gelaufen... {error.message}</div>
          </div>
        </div>
      );
    } else if (!loaded) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="mainboard col-9 playlist">
            <div className="mainboard__inner">
              <section className="home-section alben">
                <h4>Songs</h4>
                <h3>Browse Songs</h3>

                <div className="legende">
                  <div className="col-1 text-center">
                    <p>#</p>
                  </div>
                  <div className="col-4">
                    <p>Titel</p>
                  </div>
                  <div className="col-7 text-right">
                    <i className="fas fa-clock"></i>
                  </div>
                </div>

                <SimpleBar style={{ maxHeight: 467 }}>
                  <ul>
                    {tracksAll.map((track, index) => (
                      <li
                        key={index}
                        className={
                          this.state.songIndex === index && this.state.playing
                            ? "active"
                            : null
                        }
                        onClick={() => {
                          this.selectTrack(parseInt(index));
                        }}
                      >
                        <div className="tracks">
                          <div className="col-1 icon text-center">
                            {this.state.songIndex === index &&
                            this.state.playing ? (
                              <i className="fas fa-volume-up"></i>
                            ) : (
                              <span>{index + 1}</span>
                            )}
                          </div>
                          <div className="col-4">
                            <div className="row inner align-items-center">
                              <div className="col-3">
                                <img src={track.image} alt={track.name} />
                              </div>
                              <div className="col-9">
                                {track.name}
                                <span>{track.artist_name}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-7 text-right">
                            {this.timeFormat(track.duration)}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </SimpleBar>
              </section>
            </div>
          </div>

          <Playboard
            {...this.state}
            audioEl={this.audioEl}
            timelineEl={this.timelineEl}
            durationEl={this.durationEl}
            playSong={this.playSong}
            nextSong={this.nextSong}
            prevSong={this.prevSong}
            loopToggle={this.loop}
            shuffleToggle={this.shuffle}
            handleTimeUpdate={this.handleTimeUpdate}
            audioEnd={this.audioEnd}
            updateTimeline={this.updateTimeline}
            saveSongDuration={this.saveSongDuration}
            timeFormat={this.timeFormat}
          />
        </>
      );
    }
  }
}

export default Songs;