import React from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Virtual, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      alben: [],
      artisten: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const alben =
      "https://api.jamendo.com/v3.0/albums/?client_id=2bae85e9&format=json&limit=20&order=popularity_week";
    const artisten =
      "https://api.jamendo.com/v3.0/artists/?client_id=2bae85e9&format=json&limit=20&order=popularity_total";

    Promise.all([
      fetch(alben).then((value) => value.json()),
      fetch(artisten).then((value) => value.json()),
    ]).then(
      (data) => {
        this.setState({
          loaded: true,
          alben: data[0].results,
          artisten: data[1].results,
        });
        // console.log(data);
      },
      (error) => {
        this.setState({
          loaded: false,
          error,
        });
      }
    );
  }

  render() {
    const { error, loaded, alben, artisten } = this.state;
    SwiperCore.use([Virtual, Navigation]);

    if (error) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div>Irgendwas ist schief gelaufen... {error.message}</div>
          </div>
        </div>
      );
    } else if (!loaded) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <section className="home-section">
              <h4>Albums</h4>
              <h3>Popular Week</h3>
              <Swiper
                className="albums"
                slidesPerView={5}
                spaceBetween={30}
                navigation
                virtual
              >
                {alben.map((album) => (
                  <SwiperSlide key={album.id}>
                    <Link
                      to={`/albums/${album.id}`}
                      title={album.name.replace("&amp;", "&")}
                    >
                      <div className="card-albums">
                        <div className="card-albums__image">
                          <img
                            src={album.image}
                            alt={album.name.replace("&amp;", "&")}
                          />
                        </div>
                        <div className="card-albums__text">
                          <h5>{album.name.replace("&amp;", "&")}</h5>
                          <h6>{album.artist_name}</h6>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>

            <section className="home-section">
              <h4>Artists</h4>
              <h3>Popular</h3>
              <Swiper
                className="artists"
                slidesPerView={7}
                spaceBetween={30}
                navigation
                virtual
              >
                {artisten.map((artist) => (
                  <SwiperSlide key={artist.id}>
                    <Link
                      to={`/artists/albums/${artist.id}`}
                      title={artist.name.replace("&amp;", "&")}
                    >
                      <div className="artist-albums">
                        <div className="artist-albums__image">
                          <img
                            src={artist.image}
                            alt={artist.name.replace("&amp;", "&")}
                          />
                        </div>
                        <div className="artist-albums__text">
                          <h5>{artist.name.replace("&amp;", "&")}</h5>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </div>
        </div>
      );
    }
  }
}

export default Home;