import React from "react";
import Sideboard from "./partials/Sideboard";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Albums from "./pages/Albums";
import Artists from "./pages/Artists";
import Songs from "./pages/Songs";
import Playlists from "./pages/Playlists";
import PlaylistView from "./pages/PlaylistView";
import ArtistAlbums from "./pages/ArtistAlbums";

class Musicplayer extends React.Component {
  render() {
    return (
      <div className="musicplayer d-flex justify-content-center align-items-center">
        <div className="musicplayer__inner">
          <div className="container">
            <div className="row">
              <Router>
                <Sideboard />
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/albums" exact component={Albums} />
                  <Route path="/artists" exact component={Artists} />
                  <Route path="/songs" exact component={Songs} />
                  <Route path="/playlists" component={Playlists} />
                  <Route path="/albums/:id" component={PlaylistView} />
                  <Route path="/artists/albums/:id" component={ArtistAlbums} />
                  <Route path="/playlists/:id" component={PlaylistView} />
                </Switch>
              </Router>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Musicplayer;
