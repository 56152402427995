import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

class Playlists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      playlisten: [],
      loaded: false
    };
  }

  componentDidMount() {
    const playlisten =
      "https://api.jamendo.com/v3.0/playlists/?client_id=2bae85e9&format=json&limit=50&imagesize=300";

    fetch(playlisten)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            loaded: true,
            playlisten: data.results,
          });
          // console.log(data);
        },
        (error) => {
          this.setState({
            loaded: false,
            error,
          });
        }
      );
  }

  render() {
    const { error, loaded, playlisten } = this.state;

    if (error) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div>Irgendwas ist schief gelaufen... {error.message}</div>
          </div>
        </div>
      );
    } else if (!loaded) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <section className="home-section alben">
              <h4>Playlists</h4>
              <h3>Browse Playlists</h3>

              <div className="outer">
                <SimpleBar style={{ maxHeight: 526 }}>
                  <div className="row m-0">
                    {playlisten.map((playlist) => (
                      <div className="col-3" key={playlist.id}>
                        <Link
                          to={`/playlists/${playlist.id}`}
                          title={playlist.name.replace("&amp;", "&")}
                        >
                          <div className="card-albums">
                            <div className="card-albums__text">
                              <h5>{playlist.name.replace("&amp;", "&")}</h5>
                              <h6>{playlist.user_name}</h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </SimpleBar>
              </div>
            </section>
          </div>
        </div>
      );
    }
  }
}

export default Playlists;