import React from "react";

class Playboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
    };
  }

  volumeBarRef = React.createRef();
  volumeProgress = React.createRef();

  componentDidMount() {
    this.setDefaultVolume();
  }

  componentDidUpdate() {
    this.addClass();
  }

  setDefaultVolume = () => {
    const audio = this.props.audioEl.current;
    const volumeProgress = this.volumeProgress.current;

    audio.volume = 0.3;
    volumeProgress.style.width = `${audio.volume * 100}%`;
  };

  adjustVolume = e => {
    const audio = this.props.audioEl.current;
    const volumeProgress = this.volumeProgress.current;
    const volumeBar = this.volumeBarRef.current;
    const volumeBarWidth = volumeBar.offsetWidth;
    const currentPosition = e.nativeEvent.offsetX;
    const volumeRatio = currentPosition / volumeBarWidth;

    audio.volume = volumeRatio;
    volumeProgress.style.width = `${volumeRatio * 100}%`;
  };

  addClass() {
    if (this.title.scrollWidth > this.title.clientWidth) {
      setTimeout(() => {
        this.setState({ animate: true });
      }, 300);
    } else {
      setTimeout(() => {
        this.setState({ animate: false });
      }, 300);
    }
  }

  render() {
    const {
      tracksAll,
      playing,
      songIndex,
      currentTime,
      // loop,
      // shuffle,
    } = this.props;

    return (
      <>
        <div className="playboard col-12">
          <div className="playboard__inner">
            <div className="row">
              <div className="col-3 sec-1">
                <div className="cover-title">
                  <div className="row">
                    <div className="col-3">
                      <img src={tracksAll.image} alt=""></img>
                    </div>
                    <div className="col-9 d-flex align-items-center">
                      <div className="artist-info">
                        <h2
                          className={
                            this.state.animate === true
                              ? "title animate"
                              : "title"
                          }
                          ref={(title) => {
                            this.title = title;
                          }}
                        >
                          {tracksAll.tracks[songIndex].name}
                        </h2>
                        <h3
                          className="artist"
                          ref={(artist) => {
                            this.artist = artist;
                          }}
                        >
                          {tracksAll.artist_name}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-2 sec-2 d-flex justify-content-start align-items-center">
                <div className="play-prev-next d-flex align-items-center">
                  <div className="left">
                    <i
                      id="prev"
                      onClick={this.props.prevSong}
                      className="fas fa-backward"
                    ></i>
                  </div>
                  <div className="middle text-center">
                    <i
                      id="play"
                      onClick={this.props.playSong}
                      className={playing ? "fas fa-pause" : "fas fa-play"}
                    />
                  </div>
                  <div className="right">
                    <i
                      id="next"
                      onClick={this.props.nextSong}
                      className="fas fa-forward"
                    ></i>
                  </div>
                </div>
              </div>

              <div className="col-4 sec-3 d-flex justify-content-start align-items-center">
                <div className="timeline">
                  <div className="row align-items-center">
                    <div className="start-time col-1">
                      {this.props.timeFormat(currentTime)}
                    </div>
                    <div className="col-10 d-flex align-items-center">
                      <div
                        ref={this.props.timelineEl}
                        className="timeline-bar"
                        onClick={this.props.updateTimeline}
                      >
                        <div className="timeline-bar__outer">
                          <div
                            ref={this.props.durationEl}
                            className="timeline-bar__progress"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="end-time col-1 text-right">
                      {this.props.timeFormat(
                        tracksAll.tracks[songIndex].duration
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-1 sec-4 d-flex justify-content-start align-items-center">
                <div className="repeat-random text-center">
                  {/* <i
                    className={loop ? "fas fa-sync active" : "fas fa-sync"}
                    onClick={this.props.loopToggle}
                  ></i>
                  <i
                    className={
                      shuffle ? "fas fa-random active" : "fas fa-random"
                    }
                    onClick={this.props.shuffleToggle}
                  ></i> */}
                  <a href={tracksAll.tracks[songIndex].audiodownload}>
                    <i className="fas fa-download"></i>
                  </a>
                  {/* <i className="fas fa-heart"></i> */}
                </div>
              </div>

              <div className="col-2 sec-5 d-flex justify-content-start align-items-center">
                <div className="volume d-flex align-items-center">
                  <div className="left">
                    <i className="fas fa-volume-down"></i>
                  </div>
                  <div className="middle">
                    <div
                      ref={this.volumeBarRef}
                      className="volume-bar"
                      onClick={this.adjustVolume}
                    >
                      <div className="volume-bar__outer">
                        <div
                          ref={this.volumeProgress}
                          className="volume-bar__progress"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="right text-right">
                    <i className="fas fa-volume-up"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <audio
          ref={this.props.audioEl}
          src={tracksAll.tracks[songIndex].audio}
          onTimeUpdate={this.props.handleTimeUpdate}
          onEnded={this.props.audioEnd}
          onCanPlay={this.props.saveSongDuration}
        />
      </>
    );
  }
}

export default Playboard;