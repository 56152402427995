import React from "react";
import { NavLink } from "react-router-dom";

class Sideboard extends React.Component {
  render() {
    return (
      <div className="sideboard col-3">
        <div className="sideboard__inner">
          <NavLink exact to="/" className="logo">
            <h1>
              <span>Jamendo</span> Music
            </h1>
          </NavLink>
          <h3>Navigation</h3>
          <nav>
            <ul>
              <li>
                <NavLink exact to="/">
                  <i className="fas fa-home"></i>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/albums">
                  <i className="fas fa-headphones"></i>
                  Albums
                </NavLink>
              </li>
              <li>
                <NavLink to="/artists">
                  <i className="fas fa-users"></i> Artists
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/playlists">
                  <i className="fas fa-compact-disc"></i> Playlists
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/songs">
                  <i className="fas fa-music"></i> Songs
                </NavLink>
              </li>
            </ul>
          </nav>

          <div className="version">
            <p>Version 0.0.1</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Sideboard;