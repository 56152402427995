import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

class Artists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      artisten: [],
      loaded: false
    };
  }

  componentDidMount() {
    const artisten =
      "https://api.jamendo.com/v3.0/artists/?client_id=2bae85e9&format=json&limit=50";

    fetch(artisten)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            loaded: true,
            artisten: data.results,
          });
          // console.log(data);
        },
        (error) => {
          this.setState({
            loaded: false,
            error,
          });
        }
      );
  }

  render() {
    const { error, loaded, artisten } = this.state;

    if (error) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div>Irgendwas ist schief gelaufen... {error.message}</div>
          </div>
        </div>
      );
    } else if (!loaded) {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mainboard col-9">
          <div className="mainboard__inner">
            <section className="home-section artisten">
              <h4>Artists</h4>
              <h3>Browse Artists</h3>

              <div className="outer">
                <SimpleBar style={{ maxHeight: 526 }}>
                  <div className="row m-0">
                    {artisten.map((artist) => (
                      <div className="col-3" key={artist.id}>
                        <Link
                          to={`/artists/albums/${artist.id}`}
                          title={artist.name.replace("&amp;", "&")}
                        >
                          <div className="card-albums">
                            <div className="card-albums__image">
                              <img
                                src={
                                  artist.image === ""
                                    ? artist.image.replace(
                                        "",
                                        "https://via.placeholder.com/200"
                                      )
                                    : artist.image
                                }
                                alt={artist.name.replace("&amp;", "&")}
                              />
                            </div>
                            <div className="card-albums__text">
                              <h5>{artist.name.replace("&amp;", "&")}</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </SimpleBar>
              </div>
            </section>
          </div>
        </div>
      );
    }
  }
}

export default Artists;